<template>
    <div class="box">
        <div class="headerFixed">
            <!-- 顶部搜索栏 -->
            <div class="searchBox">
                <div class="city" @click="showCityList">
                    {{ city }}
                </div>
                 <img src="@/assets/img/down.svg" alt="" class="down-icon">
                <span class="line"></span>
                <div class="inputBox">
                    <img src="@/assets/img/search.svg" class="icon">
                    <input type="text" placeholder="小区/写字楼/学校等" class="input-style" v-model="address" @input="searchAddress" @keyup.enter="searchAddress">
                </div>
            </div>

            <!-- 当前定位 -->
            <div class="location-box">
                <div class="location-title">当前定位</div>
                <div class="location-content">
                    <div class="location-address" @click="selectLocationAddr">{{ mapAddress }}</div>
                    <div class="right-location" @click="location">
                        <img src="@/assets/img/orange_location.svg" alt="" class="icon">
                        重新定位
                    </div>
                </div>
            </div>

            <!-- 占位栏 -->
            <div class="line"></div>

            <!-- 创建服务地址 -->
            <div class="myAddress">
                <div class="title">我的服务地址</div>
                <div class="createAddr" @click="addAddress">创建服务地址</div>
            </div>
        </div>
        <!-- {{ searchList.length }} -->
        <div class="addressList" v-if="!showSearchList">
            <div v-for="(item, index) in myAddressList" class="address-item" :key="index" @click="selectAddress(item, 0)">
                <div class="addr">{{ item.detailAddress}}</div>
                <div class="desc">{{ item.detailAddressDetails }}</div>
            </div>
        </div>
        <div class="addressList" v-if="showSearchList">
            <div v-for="(item, index) in searchList" class="address-item" :key="index" @click="selectAddress(item, 1)">
                <div class="addr">{{ item.name }}</div>
                <div class="desc">{{ item.address }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import AMapLoader from '@amap/amap-jsapi-loader';
    import Location from '@/utils/location'
    import {poiSearch} from '@/api/city'
    import {myAddress} from '@/api/my'
    import { getCityCode } from "@/api/index";
    import { throttle } from "@/utils/index"

    export default {
        data(){
            return{
                city:'定位中...',
                cityCode:0,
                address:'',             // 搜索使用
                mapAddress:'定位中...',          // 定位使用
                lat:"",
                lng:"",
                AMap:null,
                POIList:[],
                showSearchList:false,
                myAddressList:[],
                throttledCheckScroll:null,              // 存储节流函数实例
                page:1,
                isLoad:true,
                searchList:[]
            }
        },
        created(){
            const selectCity = JSON.parse(sessionStorage.getItem('selectCity'))
            if (selectCity) {
                sessionStorage.removeItem('selectCity')
                this.city = selectCity.cityInfo.name
                this.cityCode = selectCity.cityInfo.cityCode
                this.mapAddress = ""
                this.getGaoDeCityCode(this.cityCode)
            }else{
                this.setMap()
            }
            
            this.getMyAddress()
            
        },
        mounted(){
            this.initMap()
            
             //    监听页面滚动到底部,
            this.throttledCheckScroll = throttle(this.handleScroll, 200); 
            window.addEventListener('scroll', this.throttledCheckScroll)
        },
        destroyed() {
            window.removeEventListener('scroll', this.throttledCheckScroll)
        },
        methods:{
            handleScroll() {  
                const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;  
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;  
                const clientHeight = window.innerHeight || document.documentElement.clientHeight; 
                // 判断是否滚动到底部  
                if (scrollTop >0 && scrollTop + clientHeight >= scrollHeight - 100 && this.isLoad) { // 减去100是为了在距离底部还有一点距离时就触发  
                    console.log('滚动到底部了');  
                    // 这里可以添加你的逻辑，比如加载更多数据 
                    this.getMyAddress()
                }  
            },  
            setMap(){
                AMapLoader.load({
                    plugins: ['AMap.Geolocation'],
                }).then((AMap) => {
                    Location.getCurrentLocation(AMap, (result) => {
                        // 如果城市是空，则取省份名称
                        this.city = result.addressComponent.city == "" ? result.addressComponent.province : result.addressComponent.city
                        this.cityCode = result.addressComponent.citycode
                        this.mapAddress = result.pois[0].name || result.addressComponent.building
                        this.lat = result.position.lat
                        this.lng = result.position.lng
                    }, () => {
                        this.$toast("定位失败，请检查网络或GPS是否正常开启")
                    }, true)
                }).catch(e => {
                    console.log(34)
                    console.log(e);
                });
            },
            transformStream(v, i){
                let ar = v.split(' ')
                return ar[i]
            },  
            getMyAddress(){
                myAddress({
                    pageIndex:this.page,
                    pageSize:10,
                }).then(res=>{
                    if(res.code == 10000){
                        if(res.data.records.length == 0){
                            this.isLoad = false
                            return
                        }
                        res.data.records.forEach(item=>{
                            let address = item.detailAddress
                            item.detailAddress = this.transformStream(address, 0)
                            item.detailAddressDetails = this.transformStream(address, 1)
                        })
                        this.myAddressList = this.myAddressList.concat(res.data.records)
                        this.page++;
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }, 
            initMap(){
                 AMapLoader.load({
                    plugins: ["AMap.Geolocation", "AMap.PlaceSearch"],
                })
                .then((AMap) => {
                    this.AMap = AMap
                })
                .catch((e) => {
                    console.log(e);
                })
            },
            searchNearby(keywords){
                poiSearch({
                    keywords:keywords,
                    region:this.cityCode
                }).then(res=>{
                    this.searchList = res
                }).catch(err=>{
                    console.log(err)
                })
            },
            searchAddress(){
                // 查询附近周边
                if(this.address == ""){
                    this.showSearchList = false
                }else{
                    this.showSearchList = true
                }

                this.searchNearby(this.address)

              
            },
            location(){
                if(this.mapAddress == "定位中..."){
                    let _this = this
                    _this.$store.commit("loadingControl",true)
                    wx.getLocation({
                        type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                        success: function (res) {
                            _this.setMap();
                        },
                        fail: function (err) {
                            _this.$toast("定位失败，请检查网络或GPS是否正常开启")
                        },
                        complete: () => {
                            _this.$store.commit("loadingControl", false)
                        }
                    });
                }else{

                    this.setMap()
                }
            },
            showCityList(){
                this.$router.push('/cityList')
            },
            addAddress(){
                this.$router.push('/addAddress')
            },
            selectLocationAddr(){
                let params = {
                    cityCode:this.cityCode,
                    detailAddress:this.mapAddress,
                    lat:this.lat,
                    lng:this.lng
                }
                this.selectAddress(params)
            },
            async selectAddress(val, i){
                let params;
                if(i == 1){
                    // 当前定位点击
                    let latlng = val.location.split(",")
                    params = {
                        cityCode:val.citycode,
                        detailAddress:val.name,
                        lat:latlng[1],
                        lng:latlng[0]
                    }
                    sessionStorage.setItem('selectCityAddress', JSON.stringify({ cityInfo: params}))
                    getCityCode({ locationCode: val.cityCode == null ? val.citycode : val.cityCode}).then(
                        res => {
                            localStorage.setItem("cityCode", res.data);
                            this.$router.go(-1)
                        }
                    );
                }else{
                    // 我的服务地址点击
                    sessionStorage.setItem('selectCityAddress', JSON.stringify({ cityInfo: val}))
                    localStorage.setItem("cityCode", val.cityCode);
                    this.$router.go(-1)
                }
                
                
            },
            // 获取真实高德地图cityCode
            async getGaoDeCityCode(code){
                getCityCode({ locationCode: code}).then(
                    res => {
                        localStorage.setItem("cityCode", res.data);
                    }
                );
            }
        }
    }
</script>

<style lang="less" scoped>
    @import "../../styles/base.less";

    .box{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: 4.2rem;
        .headerFixed{
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 100;
            background-color: #fff;
        }
        .addressList{
            flex: 1;
            overflow-y: auto;
        }
    }

    .searchBox{
        .marginBox();
        background-color: #F5F5F5;
        border-radius: .5rem;
        padding:.25rem .6rem;
        display: flex;
        align-items: center;
        font-size: .35rem;
        .city{
            // 超出文字隐藏
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 1.5rem;
            // width: 1.5rem;
            .city-name{
                font-weight: bold;
            }
            
        }
        .down-icon{
            width: .35rem;
            height: .35rem;
            margin-left: .1rem;
        }

        .line{
            width: 1px;
            height: .5rem;
            background-color: #DADADA;
            margin:0 .25rem;
        }
        .inputBox{
            flex: 1;
            display: flex;
            align-items: center;
            .icon {
                width: .45rem;
                height: .45rem;
                margin-left: .1rem;
            }
            .input-style{
                background-color: #F5F5F5;
                .input-style()
                
            }
        }
    }

    .location-box{
        .marginBox();
        margin-top: .4rem;
        font-size: .35rem;
        .location-title{
            font-weight: bold;
            color: #989898;
        }
        .location-content{
            margin-top: .25rem;
            .flex-row-between();
            .location-name{
                color: #999;
            }
            .right-location{
                color: #F8A24D;
                .icon{
                    width: .3rem;
                    height: .3rem;
                }
            }
        }
    }

    .line{
        width: 100%;
        height: .2rem;
        background-color: #F5F5F5;
    }

    .myAddress{
        display: flex;
        font-size: .35rem;
        justify-content: space-between;
        align-items: center;
        .marginBox();
        .title {
            font-weight: bold;
            color: #989898;
        }
        .createAddr{
            color: red;
        }
    }

    .addressList{
        .marginBox();
        font-size: .35rem;
       
        .address-item{
            border-bottom: 1px solid #F5F5F5;
            padding: .4rem 0;
            .addr{
                font-weight: bold;
                color: #333;
                margin-bottom: .2rem;
            }
            .desc{
                color: #999;
                margin-top: .1rem;
            }
        }
    }
</style>